import {useTranslation}                    from "react-i18next";
import {useAuth}                           from "@/services/Auth";
import React, {useState}                   from "react";
import {Button, Col, Form, Row, Spinner}   from "react-bootstrap";
import { stringify } from "csv-stringify/lib/sync";
import { parse } from 'csv-parse/lib/sync';
import jschardet from "jschardet";

function ImportUploadForm({importSchema, endpoint, fields, onSuccess, onFailure, filter})
{
    const {t} = useTranslation();
    let auth = useAuth();

    const postRequest = auth.postRequest;

    const [fileContent, setFileContent] = useState('UPLOAD');
    const [fileInputEvent, setFileInputEvent] = useState('UPLOAD');
    const [uploading, setUploading] = useState(false);
    const [errors, setErrors] = useState([]);

    if(!endpoint){
        return;
    }

    const inputFile = (file) => {
        const reader = new FileReader();

        reader.onload = event => {

            var lines;

            const bufferByteLength = event.target.result.byteLength;
            const bufferUint8Array = new Uint8Array(event.target.result, 0, bufferByteLength);

            let resultString = new TextDecoder().decode(bufferUint8Array);

            const detectedEncoding = jschardet.detect(resultString);

            let decodedUTF;

            try {
                const decoder = new TextDecoder("utf-8", { fatal: true });
                decodedUTF = decoder.decode(resultString)
            } catch (error) {

                const decoder = new TextDecoder(detectedEncoding.encoding);

                let decoded = decoder.decode(event.target.result)

                const encoder = new TextEncoder();
                const utf8Content = encoder.encode(decoded);

                decodedUTF = new TextDecoder("utf-8").decode(utf8Content)
            }

            lines = decodedUTF
                .split(/\r?\n/)
                .filter(value => value.replace(/[;|,]+/,'').trim() !== '');

            var binary;

            var attribute_keys = {};

            if(importSchema instanceof Array)
            {
                const headers = parse(lines.shift()).pop();

                for(let i in importSchema)
                {
                    var key = importSchema[i];
                    var translation = t(key);

                    for(let j in headers)
                    {
                        if(translation == headers[j])
                        {
                            attribute_keys[key] = j;
                            headers[j] = key;
                        }
                    }
                }

                lines.unshift(stringify([headers], {}).replace(/(\r|\n)/g, ''));
            }

            if(typeof filter == 'function')
            {
                for(var i in lines)
                {
                    if(i != 0)
                    {
                        var record = parse(lines[i]).pop();

                        record = filter(record, attribute_keys, i);

                        lines[i] = stringify([record], {}).replace(/(\r|\n)/g, '');
                    }
                }
            }

            lines = lines.join('\n');

            if(!/^\uFEFF/.test(lines))
            {
                lines = "\uFEFF" + lines;
            }

            binary = new Blob([lines], {type: 'text/plain;charset=utf-8', encoding: 'UTF-8'});
            setFileContent(new File([binary], 'file.csv'));
        };

        if(file instanceof File)
        {
            reader.readAsArrayBuffer(file);
        }
        else
        {
            setFileContent(null);
        }

    };

    const uploadFile = () => {
        if (fileContent !== "UPLOAD") {
            setUploading(true);

            const formData = new FormData();

            if(fields){
                for (const field in fields) {
                    formData.append(field, fields[field]);
                }
            }

            formData.append('import_file', fileContent);

            postRequest(endpoint, formData)
                .then(response => {
                    if (response.data.message === 'OK') {
                        if(onSuccess != undefined){
                            onSuccess()
                        }
                    } else if (response.data.message === 'ERROR') {
                        setErrors(response.data.errors);

                        if(onFailure != undefined){
                            onFailure(response.data.errors);
                        }
                    }

                })
                .catch(error => {
                    if (error.response) {
                        setErrors(error.response.data.errors);
                    }

                    if(onFailure != undefined){
                        onFailure(error);
                    }
                }).finally(()=>{
                    setUploading(false)

                    if(fileInputEvent !== "UPLOAD") {
                        fileInputEvent.target.value = null
                    }
                })

        }
    }

    return (
        <>
            <Row>
                <Col className="col-md-8 col-12">
                    <Form.Group controlId="formFileLg" className="mt-1">
                        <Form.Control type="file" accept=".csv"
                                      onChange={(e) => { setFileInputEvent(e); inputFile(e.target.files[0]) }}/>
                    </Form.Group>
                </Col>
                <Col className="col-md-4 col-12 d-flex align-items-center">
                    {
                        !uploading ?
                            <Button type="submit" variant="primary" style={{width: '100%'}} onClick={() => uploadFile()} disabled={fileContent === 'UPLOAD'}>
                                {t('upload')}
                            </Button>
                            :
                            <Button variant="primary" disabled style={{width: '100%'}}>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                <span className="mx-2"> {t('please_wait')}</span>
                            </Button>
                    }

                </Col>
            </Row>
            {
                errors.length !== 0 &&
                <Row>
                    {
                        errors.map((error, index) => <p key={index} className="text-danger">{error}</p>)
                    }
                </Row>
            }
        </>
    );

}

export default ImportUploadForm;
